import { PureComponent } from 'react';

// Icon produced by FontAwesome project: https://github.com/FortAwesome/Font-Awesome/
// License: CC-By 4.0
export class DeleteIcon extends PureComponent {
  render() {
    return (
      <svg viewBox="0 0 448 512" role="img" width="24" height="24" {...this.props}>
        <path
          fill="currentColor"
          d="M268 416c0 13.3-10.7 24-24 24s-24-10.7-24-24V240c0-13.3 10.7-24 24-24s24 10.7 24 24v176zm64-176c-13.3 0-24 10.7-24 24v176c0 13.3 10.7 24 24 24s24-10.7 24-24V240c0-13.3-10.7-24-24-24zm-192 0c-13.3 0-24 10.7-24 24v176c0 13.3 10.7 24 24 24s24-10.7 24-24V240c0-13.3-10.7-24-24-24zm224-176H325.5l-9.4-18.7c-4.5-8.7-13.7-14.3-23.7-14.3H155.6c-10 0-19.2 5.6-23.7 14.3L122.5 64H76c-24.3 0-44 19.7-44 44v20c0 13.3 10.7 24 24 24h344c13.3 0 24-10.7 24-24v-20c0-24.3-19.7-44-44-44zM53.2 464c0 26.5 21.5 48 48 48h245.6c26.5 0 48-21.5 48-48V128H53.2v336z"
        />
      </svg>
    );
  }
}
